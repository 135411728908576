import React from 'react';

import Figure from 'react-bootstrap/Figure';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Modal from 'react-bootstrap/Modal';

import './Furia.css';

const Navi = () => (
    <Navbar expand="sm" className="navbar-dark fixed-top bg-dark">
        <Container>
            <Navbar.Brand href="#home">Nocna Furia</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">    
                    <Nav.Link href="#home">O imprezie</Nav.Link>
                    <Nav.Link href="#rules">Regulamin</Nav.Link>
                    <Nav.Link href="#tickets">Bilety</Nav.Link>
                    <Nav.Link href="#contact">Kontakt</Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Container>
    </Navbar>
);

const About = () => (
    <article>
        <section>
            <h2 id="about" className="red-head">O wydarzeniu</h2>
            <p>Nocna Furia to cykl imprez mających na celu integrację społeczności futerkowej, puppy oraz pony a także sympatyków tych kultur. Impreza odbędzie się w klubie Bordeaux, w samym centrum Łodzi.</p>
        </section>
    
        <section>
            <h3 className="red-head">Czego się spodziewać na miejscu</h3>
            <p>Za uprzejmością Klubu udostępniamy Wam:</p>
            <ul>
                <li>Bar wyposażony w liczne alkohole, energetyki i napoje bezalkoholowe</li>
                <li>Czystą toaletę oraz prysznice &mdash; gender neutral</li>
                <li>Nie za głośną muzykę w klimacie rock, metal, industrial</li>
                <li>Kilka pomieszczeń typu Darkroom &mdash; Yiff Zone</li>
                <li>Miejsce do zabaw z linkami &mdash; Shibari Zone</li>
                <li>Zniewalający sprzęt i miejsce do tortur &mdash; BDSM Zone</li>
                <li>Różne zabawki z zakresu erotyki i BDSM &mdash; milker, worek próżniowy, itp.</li>
                <li>Możliwość zapozowania niegrzecznych scen czy nawet nakręcenia filmu na Murrtube (po umówieniu z organizatorem &mdash; dla zagwarantowania prywatności innych uczestników wydarzenia)</li>
            </ul>
        </section>

        <section>
            <h3 className="red-head">Zdjęcie grupowe z IV edycji Nocnej Furii</h3>

            <Figure>
                <Figure.Image width={800} height={600} alt="Zdjęcie grupowe z IV edycji Nocnej Furii" src="img/group.jpg" />
            </Figure>
        </section>

        <section>
            <h3 className="red-head">Nocna Furia V</h3>
            <p>Piąta edycja Nocnej Furii rozpocznie się 2 lutego 2024. Na teren klubu Bordeaux (Plac Zwycięstwa 2C, Łódź) wpuścimy Was w godzinach 20 do 22. Impreza kończy się o 3:30 nad ranem dnia następnego.</p>
            <p>Tym razem impreza ma charakter zamknięty &mdash; wpuszczamy tylko osoby zapisane oraz zaproszone. Rejestracji oraz opłaty można dokonać <a href="#tickets">poniżej</a>. Koszt wejściówki wynosi 50 zł do końca stycznia. 1 lutego oraz w dniu imprezy będzie to już 80 zł. Tradycyjnie z opłaty zwolnieni są fursuiterzy oraz osoby zaproszone. Aby zostać uznanym za fursuitera trzeba mieć ze sobą przynajmniej futrzasty łebek oraz <a href="#tickets">przepustkę fursuitera</a>.</p>
            <p>Podsumowując: każdy uczestnik imprezy musi na bramce okazać otrzymany kod QR.</p>
            <p>Na Nocną Furię wstęp mają tylko osoby pełnoletnie.</p>
        </section>

        <section>
                <h3 className="red-head">Dress Code</h3>
                <p>Preferujemy futerka, zwierzęce kigurumi, okrycia fetyszowe, latex, skórę, goliznę, itp. Odradzamy strój typu jeansy i T-shirt. Kategorycznie zabroniony jest ubiór formalny.</p>
                <p>Klub posiada szatnię z szafkami, więc możecie przebrać się wygodnie na miejscu.</p>
        </section>

        <section>
                <h3 className="red-head">Jesteśmy otwarci na Wasze pomysły i inicjatywy!</h3>
                <p>Jeśli chcecie zrealizować jakąś swoją fantazję lub macie pomysł na ciekawy pokaz to koniecznie dajcie mi znać!</p>
                <p>Chcecie przyjść z własnymi zabawkami? Żaden problem, zabierzcie je ze sobą.</p>
                <p>Aby być na bieżąco śledźcie także <a href="https://t.me/nocna_furia">kanał Nocnej Furii <img src="img/telegram.png" alt="Telegram" width="24" height="24" /></a> na Telegramie.</p>
        </section>
    </article>
);

const Rules = () => (
    <article>
        <section>
            <h2 id="rules" className="red-head">Regulamin Imprezy "Nocna Furia V"</h2>
            <ol>
                <li>Każdy uczestnik imprezy deklaruje zapoznanie się z niniejszym regulaminem oraz zobowiązuje się do jego przestrzegania.</li>
                <li>Na imprezę wstęp mają wyłącznie osoby pełnoletnie. Organizator zastrzega sobie prawo do wglądu w dokument potwierdzający wiek uczestnika.</li>
                <li>Impreza ma charakter zamknięty. Poza organizatorami i obsługą, na teren klubu wejść mogą wyłącznie osoby posiadające wykupioną wejściówkę, imienne zaproszenie bądź przepustkę fursuitera.</li> 
                <li>Do klubu można wejść dnia 2 lutego 2024, od godziny 20 do godziny 22. Impreza kończy się o godzinie 3:30 dnia następnego.</li>
                <li>Opłata wejściowa wnoszona jest przez system płatności elektronicznych (<a href="#tickets">dostępny poniżej</a>) i wynosi:
                    <ul>
                        <li>50 zł &mdash; do końca stycznia 2024,</li>
                        <li>80 zł &mdash; w terminie późniejszym.</li>
                    </ul>
                </li>
                <li>Z opłaty wejściowej zwolnieni są:
                    <ul>
                        <li>Fursuiterzy (należy mieć ze sobą przynajmniej łebek) &mdash; pod warunkiem <a href="#tickets">wygenerowania sobie</a> darmowej przepustki fursuitera,</li>
                        <li>osoby zaproszone &mdash; pod warunkiem okazania otrzymanego zaproszenia.</li>
                    </ul>
                </li> 
                <li>Wyroby tytoniowe i im podobne (e-papierosy, fajki, itp.) można palić jedynie w wyznaczonej palarni.</li>
                <li>Organizator oraz pracownicy Klubu dołożą wszelkich starań aby zapewnić bezpieczeństwo uczestnikom imprezy, jednakże nie mogą być oni pociągnięci do odpowiedzialności za żadne uszkodzenia na stroju, ciele czy psychice uczestnika powstałe z winy innych uczestników imprezy.</li>
                <li>Organizator ani pracownicy Klubu nie odpowiadają za przedmioty zgubione bądź pozostawione w lokalu.</li>
                <li>Wykonywanie własnych filmów lub zdjęć jest możliwe tylko za wcześniejszą zgodą organizatora i tylko w wyznaczonym przez niego miejscu i czasie.</li>
                <li>Do klubu nie wnosimy alkoholu z zewnątrz.</li>
                <li>Osoby łamiące prawo bądź regulamin imprezy, zachowujące się agresywnie lub zanieczyszczające lokal mogą zostać usunięte z terenu imprezy bez zwrotu kosztów.</li>
                <li>W kwestiach spornych ostateczną decyzję podejmuje organizator.</li>
            </ol>
        </section>
    </article>    
);

function Payment() {
    const [validated, setValidated] = React.useState(false);
    const formRef = React.useRef(null);
    const nickRef = React.useRef(null);

    const checkUser = () => {
        var nick = nickRef.current.value.toLowerCase();
        if(nick.includes("victor") || nick.includes("cruel") || nick.includes("nikodem"))
        {
            alert("Wygenerowanie biletu nie jest obecnie możliwe");
            return false;
        }
        return true;   
    }
    
    const handleSubmit = (event) => {
        setValidated(true);
        if(formRef.current.checkValidity() === false || checkUser() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
    };
    
    const handlePurchase = (event) => {
        setValidated(true);
        if(formRef.current.checkValidity() === true && checkUser() === true)
            window.location.href="https://buy.stripe.com/4gwbMhb2O0sxdEcdQR";
    };
    
    return (
        <article>
            <section>
                <h2 id="tickets" className="red-head">Bilety</h2>
                <Container>
                    <Form noValidate ref={formRef} validated={validated} onSubmit={handleSubmit} action="/" method="get">
                        <Row className="p-3">
                            <Form.Group md="4" controlId="validationCustom01">
                                <Form.Label>Nick:</Form.Label>
                                <Form.Control required type="text" placeholder="Jak Cię zwą?" name="nick" ref={nickRef} />
                                <Form.Control.Feedback type="invalid">To pole nie może być puste</Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row className="p-3">
                            <Col><Button type="button" className="w-100" onClick={handlePurchase}>Kup bilet</Button></Col>
                            <Col><Button type="submit" className="btn-secondary w-100">Mam fursuita</Button></Col>
                        </Row>
                    </Form>
                </Container>
            </section>
        </article>    
    );
};

const Contact = () => (
    <article>
        <section className="contact">
            <h2 id="contact" className="red-head">Kontakt</h2>
            <a href="https://t.me/OlekTk"><img src="img/telegram.png" alt="Telegram" />OlekTk</a>
            <a href="https://m.me/pomaranczowy.wilk"><img src="img/messenger.png" alt="Messenger" />Pomarańczowy Wilk</a>
            <a href="https://www.facebook.com/pomaranczowy.wilk"><img src="img/facebook.png" alt="Facebook" />Pomarańczowy Wilk</a>
            <a href="https://fetlife.com/OlekTk"><img src="img/fetlife.png" alt="Fetlife" />OlekTk</a>
            <a href="&#109;&#97;&#105;&#108;&#116;&#111;&#58;olek&#64;olek.tk"><img src="img/mail.png" alt="e-mail" />olek&#64;olek.tk</a>
        </section>
    </article>  
);

export default function Furia() {
    return(
    <Container className="p-3 text-center">
        <img src="img/furia2.png" alt="Nocna Furia" />
    </Container>
    );
}

function Main() {
    const queryParameters = new URLSearchParams(window.location.search)
    const has_ticket = queryParameters.has('ticket');
    const has_nick = queryParameters.has('nick');
    const [modalShow, setModalShow] = React.useState(has_ticket || has_nick);
    const ticket = has_ticket ? queryParameters.get('ticket').substring(3) : null;
    const nick = has_nick ? queryParameters.get('nick') : null;
    const imgRef = React.useRef(null);
    const btnRef = React.useRef(null);
    
    function tryLoad(src, timeout) {
        var tester = new Image();
        
        tester.onload = function() {
            imgRef.current.src = src;
            btnRef.current.href = src;
            btnRef.current.classList.remove("disabled");
        }
        
        tester.onerror = function() {
            setTimeout(() => (tryLoad(src, timeout + 1000)), timeout);
        }

        tester.src = src;   
    }
    
    if(has_ticket)
        tryLoad('tickets/' + ticket + '.png', 1000);
    
    if(has_nick)
    {
        var tester = new Image();
        var src = "fursuiter.php?nick=" + nick;
        tester.onload = function() {
            imgRef.current.src = src;
            btnRef.current.href = src;
            btnRef.current.classList.remove("disabled");
        }
        
        tester.src = src;
    }
    
    return(
    <Container className="p-3">
        <Navi />
        <h1 className="header font-effect-neon red-head" id="home">Witajcie na Nocnej Furii!</h1>
        <About />
        <Rules />
        <Container>
            <Row>
                <Col lg><Payment /></Col>
                <Col lg><Contact /></Col>
            </Row>
        </Container>
        <Modal className="mh-100" show={modalShow} backdrop="static" keyboard={false} size="lg" aria-labelledby="contained-modal-title-vcenter" onHide={() => setModalShow(false)} centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Twój bilet na Nocną Furię
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
                <img src="img/loading.gif" ref={imgRef} alt="Bilet" /><br /><br />
                <b>Zapisz go, zrób zrzut ekranu, lub po prostu dodaj zakładkę do tej strony</b>
            </Modal.Body>
            <Modal.Footer>
                <a className="btn btn-primary disabled" ref={btnRef} href="/" download>Pobierz</a>
            </Modal.Footer>
        </Modal>
    </Container>            
    );
};
